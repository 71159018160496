import "./Home.css";
import band from "./static/band.png";

const Home = () => {
  return (
    <div className="home">
      <img className="band" src={band} />
      <h1>About</h1>
      <p>
        Minutes Later creates music that crosses boundaries and draws from
        modern jazz, Nordic indie pop, minimalist composers and japanese
        electronic music. In their distinctive sound there is a honest
        sensitivity performed with high energy and intensity. The group consists
        of friends with a shared desire to perform innovative music free from
        genre conventions.
        <br />
        <br />
        Composer, singer and pianist Fabian Isaksson searches for strong
        rhythmic and melodic themes and performs freely associative texts with a
        raw expression.
        <br />
        <br />
        Based in Stockholm, Sweden.
        <br />
        <br />
        Saxophone - Elias Berg
        <br />
        Bass - Hugo Röhlcke
        <br />
        Drums and Guitar - Filip Sundqvist
      </p>
    </div>
  );
};

export default Home;
