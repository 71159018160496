import "./Music.css";
import album from "./static/album.png";

const Music = () => {
  return (
    <div className="music">
      <img className="sax" src={album} />
      <h1>Everything</h1>
      <p>
        Here you can listen to our upcoming debut album. Produced by Fabian
        Isaksson (MinutesLtr.) and Martin "Konie" Ehrencrona.
      </p>
      <p>
        <a
          target="_blank"
          href="https://samply.app/player/KxoKq6RGVTpFaNfiGNSc/"
        >
          Listen on Samply →
        </a>
      </p>
      <p>Listen on SoundCloud ⬇</p>
      <iframe
        width="100%"
        height="300"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1780271040%3Fsecret_token%3Ds-j9QSJG5VZox&color=%230c0c0c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      ></iframe>
      <div
        style={{
          fontSize: "10px",
          color: "#cccccc",
          lineBreak: "anywhere",
          wordBreak: "normal",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontFamily:
            "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
          fontWeight: 100,
        }}
      >
        <a
          href="https://soundcloud.com/minutesltr"
          title="MinutesLtr."
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          MinutesLtr.
        </a>
        {" · "}
        <a
          href="https://soundcloud.com/minutesltr/sets/everything/s-j9QSJG5VZox"
          title="Everything"
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          Everything
        </a>
      </div>
    </div>
  );
};

export default Music;
