import { useState } from "react";
import "./App.css";
import Home from "./Home";
import logo from "./static/logo.png";
import classNames from "classnames";
import Music from "./Music";
import Contact from "./Contact";

function App() {
  let path = window.location.pathname;
  if (!["/", "/music", "/contact"].includes(path)) {
    path = "/";
  }
  const [pathName, setPathName] = useState(path);
  console.log("🟠 → pathName:", pathName);

  return (
    <div className="page">
      <div className="content">
        <img className="logo" src={logo} />
        <div className="header">
          <a
            className={classNames("header-link", {
              selected: pathName === "/",
            })}
            href="/"
          >
            About
          </a>
          <a
            className={classNames("header-link", {
              selected: pathName === "/music",
            })}
            href="/music"
          >
            Music
          </a>
          <a
            className={classNames("header-link", {
              selected: pathName === "/contact",
            })}
            href="/contact"
          >
            Contact
          </a>
        </div>
        {pathName === "/" && <Home />}
        {pathName === "/music" && <Music />}
        {pathName === "/contact" && <Contact />}
        <div className="footer">
          <p>
            email:{" "}
            <a href="mailto:contact@minutesltr.band">contact@minutesltr.band</a>
          </p>
          <p>
            tel: <a href="tel:(+46)768810692">(+46) 076-881-06-92</a>
          </p>
          <p>
            <a target="_blank" href="https://www.instagram.com/minutesltr">
              Instagram
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
