import "./Contact.css";
import polaroid from "./static/polaroid.png";

const Contact = () => {
  return (
    <div className="contact">
      <img className="polaroid" src={polaroid} />
      <h1>Contact</h1>
      <p>
        Write to us{" "}
        <a href="mailto:contact@minutesltr.band">contact@minutesltr.band</a>
        <br />
        Call <a href="tel:(+46)768810692">(+46) 076-881-06-92</a>
        <br />
        <a target="_blank" href="https://www.instagram.com/minutesltr">
          Instagram
        </a>
      </p>
    </div>
  );
};

export default Contact;
